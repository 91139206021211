import { render, staticRenderFns } from "./BannerDefinitions.vue?vue&type=template&id=3ffd866e"
import script from "./BannerDefinitions.vue?vue&type=script&lang=js"
export * from "./BannerDefinitions.vue?vue&type=script&lang=js"
import style0 from "./BannerDefinitions.vue?vue&type=style&index=0&id=3ffd866e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VBtn,VDataTable,VIcon,VImg,VSpacer,VTextField})
